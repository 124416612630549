.ag-theme-alpine {
  --ag-cell-horizontal-border: var(--ag-row-border-width)
    var(--ag-row-border-style) var(--ag-row-border-color);
  /* Add left and right borders to each cell and use row border's properties  */
  --ag-header-column-separator-display: block;
  --ag-header-column-resize-handle-display: none;
}

.ag-header-cell-label {
  .ag-sort-indicator-container {
    .ag-sort-order {
      display: none;
    }
  }
}
