/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-grid.min.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
@import './assets/styles/variables.scss';
@import './assets/styles/themes.scss';

.ag-grid-table {
  width: 100%;
  height: calc(100vh - 204px);
}

html,
body {
  overflow: auto;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden !important;
  background-color: #fff !important;
}

nav#site-specific-nav ul:not(.in-mega)>li>a.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-content {
  margin-left: 5px;
}

.mat-accordion,
.filter-content-data {
  margin-top: 1.5rem;
  margin-left: 10px;
  margin-bottom: 10px;
}

.mat-expansion-panel {
  border-right: 1px solid #dadada;
}

.ag-header-group-cell:has(app-effective-date-header) {
  padding: 0px !important;

  app-effective-date-header {
    width: 100%;
  }

  .effective-date-field {
    mat-form-field {
      .mat-mdc-floating-label {
        font-size: var(--app-normal-text-size);
      }
    }
  }
}

.center-header {
  justify-content: center;
}

.gridUpdatedCellBg {
  background-color: #eef3e4;
}

.view-switch {
  display: inline-block;
  font-family: var(--cv-font-gotham-narrow);
  font-size: var(--cv-fontsize-14);
  color: var(--cv-bg-darkgray);

  .form-switch {
    line-height: 1.8;

    .form-check-input~.form-check-label::before {
      border-color: var(--cv-cool-darkblue) !important;
      background-color: var(--cv-cool-darkblue) !important;
      box-shadow: none;
    }

    .form-check-input~.form-check-label::after {
      background-color: #fff;
    }
  }

  .bold {
    font-weight: bold;
    color: var(--cv-cool-darkblue);
  }
}

.content {
  transition: 0.3s;
  margin-left: 60px;

  &.filterPanelOpened {
    margin-left: 250px;
  }
}

.grid-row-menu-header {
  top: -84px !important;
}

.ag-cell-dropdown-field {
  .mat-select {
    margin: 0;
    padding: 0;
    height: 100%;
    line-height: normal;
  }

  .mat-select-value {
    padding: 0;
    line-height: normal;
  }

  .mat-mdc-select-min-line {
    font-size: var(--app-normal-text-size);
  }
}

.ag-cell-dropdown-select {
  .mat-mdc-option {
    min-height: 32px !important;
    font-size: var(--app-normal-text-size) !important;
  }

  .mat-select-search-input {
    border-bottom: 1px solid var(--light-grey) !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    width: 32px;
    height: 32px;
    top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-mdc-icon-button svg,
    .mat-mdc-icon-button img {
      width: 20px;
      height: 20px;
    }
  }
}

.ag-cell-date-field {
  margin: 0;
  padding: 0;
  position: relative;
  top: -1px;
  left: -1px;
  width: 101% !important;

  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding-left: 17px;
  }

  .mat-datepicker-toggle {
    margin: 0;
    padding: 0;
    height: 100%;

    .mat-icon-button {
      height: 32px !important;
      width: 32px !important;
      padding: 0 !important;

      .mat-datepicker-toggle-default-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-mdc-icon-button svg,
    .mat-mdc-icon-button img {
      width: 20px;
      height: 20px;
    }
  }
}

.custom-editor-field {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 102%;
  display: flex;
  align-items: center;
  font-size: var(--app-normal-text-size);

  .mat-form-field-wrapper,
  .mat-mdc-form-field-wrapper {
    padding: 0;
    height: 100%;
  }

  .mat-form-field-flex,
  .mat-mdc-form-field-flex {
    height: 100%;
    align-items: center;
  }

  .mat-input-element {
    margin: 0;
    padding: 0;
    height: 100%;
    line-height: normal;
    font-size: var(--app-normal-text-size);
  }

  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    padding: 0;
  }
}

.grid-container {
  &.master-data-grid {
    height: calc(100vh - 216px);
  }

  &.clr-grid,
  &.ssp-grid {
    height: calc(100vh - 300px);

    .effective-date-field {
      mat-datepicker-toggle {
        .mat-datepicker-toggle-default-icon {
          --mdc-icon-button-icon-size: 20px;
        }
      }
    }
  }

  .modifiedGridRow {
    border-left: 8px solid var(--medium-blue);
  }
}

app-checkbox-floating-filter {
  width: 100%;
}

ag-grid-angular {
  .disabledGridRow {
    background-color: #e6e6e6a8;
  }

  .invalid {
    border: 0.5px solid #dc3545 !important;
  }

  .hyperlink-icon {
    background: url('/assets/icons/New_Window_Icon.svg') no-repeat top center;
    width: 14px;
    margin-top: 4px;
  }

}
