@import './variables.scss';
@import './custom-ag-grid-styles.scss';
@import './custom-material-styles.scss';
@import './custom-bootstrap-styles.scss';

/* CUSTOM ICONS */

.icon-btn {
  min-height: 24px;
  min-width: 24px;
  border: none;
  outline: none !important;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
  transition: background-color ease 0.3s;

  &:hover {
    filter: invert(50%) sepia(23%) saturate(6631%) hue-rotate(166deg)
      brightness(93%) contrast(101%);
  }

  &:disabled,
  &.disabled {
    filter: invert(45%) opacity(0.7);
    cursor: default;
  }
 

  &:nth-child(n + 1) {
    margin-right: 10px;
  }

  .icon-label {
    color: var(--medium-blue);
    font-size: 11px;
    margin-top: 24px;
    font-family: 'GothamNarrowBook', Arial, Helvetica, sans-serif;
    display: block;
  }

  &.save-icon {
    background: url('../icons/Save.svg') no-repeat top center;
  }

  &.undo-icon {
    background: url('../icons/Undo.svg') no-repeat top center;
  }

  &.refresh-icon {
    background: url('../icons/Refresh.svg') no-repeat top center;
  }

  &.export-icon {
    background: url('../icons/Export.svg') no-repeat top center;
  }

  &.upload-icon {
    background: url('../icons/Upload.svg') no-repeat top center;
  }

  &.version-icon {
    background: url('../icons/Version.svg') no-repeat top center;
  }

  &.draft-icon {
    background: url('../icons/Draft.svg') no-repeat top center;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

button {
  &:focus {
    outline: none !important;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
  }
}

/* CUSTOM MAT DIALOG */

.confirmation-dialog-panel {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0px;
  }

  .dialog-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dialog-header {
      color: var(--dark-blue);
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog-title {
        font-size: var(--app-heading2-text-size);
        font-weight: 700;
      }

      .dialog-title-close-btn {
        font-size: var(--app-heading1-text-size);
        cursor: pointer;
      }
    }

    .dialog-body {
      flex-grow: 1;
      padding: 20px 24px;
      min-height: 120px;
      overflow-y: auto;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px;

      .ok-btn,
      .cancel-btn {
        margin-left: 15px;
      }
    }
  }
}

/* FORM */

.form-container {
  .form-group {
    .ng-select.ng-touched.ng-invalid {
      .ng-select-container {
        border-color: red;
      }
    }

    .form-label {
      color: var(--dark-grey);
      display: block;
      font-size: var(--app-normal-text-size);
      font-weight: bold;
    }

    .form-control {
      border-radius: 0;
      color: var(--medium-grey);
      font-size: var(--app-normal-text-size);

      &.ng-touched.ng-invalid {
        border: 1px solid red;
      }
    }

    .error {
      font-size: var(--unnamed-font-size-12);
      margin-top: 3px;
      color: red;
    }
  }
}
