/* MATERIAL TAB */

.mat-mdc-tab {
  &.mdc-tab {
    opacity: 1;
  }

  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--dark-blue) !important;
    }
  }

  .mdc-tab__text-label {
    font-weight: 700;
    color: #000 !important;
  }
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--dark-blue) !important;
}

/* MATERIAL SNACKBAR */

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: var(--dark-blue);
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;
  --mat-mdc-snack-bar-button-color: #fff;

  .mdc-snackbar__surface {
    border-radius: 0 !important;
  }

  &.info {
    --mdc-snackbar-container-color: var(--info-bg-color);
    --mdc-snackbar-supporting-text-color: var(--info-text-color);
    --mat-snack-bar-button-color: var(--info-text-color);
    --mat-mdc-snack-bar-button-color: var(--info-text-color);
  }

  &.success {
    --mdc-snackbar-container-color: var(--success-bg-color);
    --mdc-snackbar-supporting-text-color: var(--success-text-color);
    --mat-snack-bar-button-color: var(--success-text-color);
    --mat-mdc-snack-bar-button-color: var(--success-text-color);
  }

  &.warning {
    --mdc-snackbar-container-color: var(--warning-bg-color);
    --mdc-snackbar-supporting-text-color: var(--warning-text-color);
    --mat-snack-bar-button-color: var(--warning-text-color);
    --mat-mdc-snack-bar-button-color: var(--warning-text-color);

    .mdc-button {
      span.mat-ripple {
        background: var(--warning-border);
      }
    }
  }

  &.error {
    --mdc-snackbar-container-color: var(--error-bg-color);
    --mdc-snackbar-supporting-text-color: var(--error-text-color);
    --mat-snack-bar-button-color: var(--error-text-color);
    --mat-mdc-snack-bar-button-color: var(--error-text-color);
  }
}

/* MATERIAL FORM FIELDS */

.mdc-form-field {
  .mdc-label {
    margin-bottom: 0;
  }
}

.mat-mdc-form-field {
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.7);
  --mdc-filled-text-field-disabled-container-color: #f0f0f0;
  --mat-form-field-container-text-size: var(--app-normal-text-size);
}

/* MATERIAL CHECKBOX */

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-icon-color: var(--medium-blue);
  --mdc-checkbox-selected-hover-icon-color: var(--medium-blue);
  --mdc-checkbox-selected-focus-icon-color: var(--medium-blue);
  --mdc-checkbox-unselected-focus-icon-color: var(--medium-blue);
  --mdc-checkbox-unselected-hover-icon-color: var(--medium-blue);
  --mdc-checkbox-unselected-icon-color: var(--medium-blue);
  --mdc-checkbox-unselected-pressed-icon-color: var(--medium-blue);
}

.mdc-checkbox {
  .mdc-checkbox__background {
    border-width: 1px !important;
    border-radius: 0px !important;
    width: 16px;
    height: 16px;
  }

  .mdc-checkbox__ripple {
    display: none;
  }
}

/* CUSTOM MAT-MENU */

.custom-mat-menu {
  &.mat-mdc-menu-panel {
    border-radius: 0;
  }

  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      font-weight: 700;
      min-height: 32px;

      &:not([disabled]):hover {
        background-color: var(--medium-blue);
        color: #fff;
      }

      .mat-mdc-menu-item-text {
        font-weight: 700;
      }
    }
  }
}
