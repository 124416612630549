/* BOOTSTRAP BUTTONS */

.btn {
  border-radius: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 15px;

  &:focus {
    box-shadow: none;
  }

  &:focus-visible,
  &:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

.btn-primary {
  color: white;
  background-color: var(--medium-blue) !important;

  &:disabled,
  &.disabled {
    background-color: var(--light-grey);
  }
}

.btn-secondary,
.btn-secondary:hover {
  color: var(--medium-blue);
  background-color: #fff;
  border: 2px solid var(--medium-blue);
  padding: 4px 15px;
}

.btn-secondary {
  &:active {
    color: var(--medium-blue) !important;
    background-color: #fff !important;
    border: 2px solid var(--medium-blue) !important;
  }

  &:disabled,
  &.disabled {
    background-color: var(--light-grey);
    border: 2px solid var(--light-grey);
    color: var(--dark-grey);
  }
}
